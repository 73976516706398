import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Glitch from 'glitch-javascript-sdk';
import Publisherheader from '../../component/layout/publisherheader';

const PublisherDashboard = () => {
    const [community, setCommunity] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        Glitch.api.Communities.list({ roles: [1, 2], order_by: 'created_at' })
            .then(response => {
                if (response.data.data.length > 0) {
                    setCommunity(response.data.data[0]);
                }
            })
            .catch(error => {});
    }, []);

    const menuItems = [
        {
            label: "Chat",
            icon: "far fa-comments",
            action: () => navigate(Navigate.titlesChatPage()),
            description: "Chat with our AI about your marketing needs."
        },
        {
            label: "Find Influencers",
            icon: "fa-bullhorn",
            action: () => navigate(Navigate.campaignsPage()),
            description: "Start an influencer campaign to market your game."
        },
        {
            label: "Automate Social Media",
            icon: "fa-robot",
            action: () => navigate(Navigate.schedulerListPage()),
            description: "Schedule and manage your social media posts efficiently."
        },
        {
            label: "Build A Community",
            icon: "fa-users",
            action: () => navigate(Navigate.communitiesAdminListPage()),
            description: "Create a forum-like system for users to engage around your game."
        },
        {
            label: "News Letter",
            icon: "fa-newspaper",
            action: () => navigate(Navigate.newsletterListPage()),
            description: "Keep your audience updated with game-related news and updates."
        },
        {
            label: "Media Kit",
            icon: "fa-photo-video",
            action: () => navigate(Navigate.mediakitsPublishersListPage()),
            description: "Prepare promotional materials for your game."
        },
        {
            label: "Find Testers",
            icon: "fa-vial",
            action: () => navigate(Navigate.playtestingPublishersListPage()),
            description: "Recruit testers to improve your game's quality."
        },
        {
            label: "Access Analytics",
            icon: "fa-chart-line",
            action: () => navigate(Navigate.reportsMainPage()),
            description: "Analyze performance metrics and insights for your campaigns."
        },
    ];

    return (
        <>
            <Publisherheader position={"relative"} />
                <section className="pageheader-section-min">
                    <div className="container">
                        <div className="section-wrapper text-center">
                            <div className="pageheader-thumb mb-4">
                            </div>
                            <h2 className="pageheader-title">Dashboard</h2>

                            <p className="lead">Welcome to your dashboard! Use the sections below to navigate and manage your activities effectively.</p>

                        </div>
                    </div>
                </section>

            <div className="container my-5">
              

                <div className="row g-4">
                    {menuItems.map((item, index) => (
                        <div key={index} className="col-md-6 col-lg-4">
                            <div 
                                className="card text-center border-0 shadow-sm h-100"
                                style={{ cursor: 'pointer' }}
                                onClick={item.action}
                            >
                                <div className="card-body">
                                    <i className={`fas ${item.icon} fa-3x mb-3 text-primary`}></i>
                                    <h5 className="card-title">{item.label}</h5>
                                    <p className="card-text">{item.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default PublisherDashboard;
