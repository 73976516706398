import React from "react";
import { Component, Fragment } from "react";

import Footer from "../../component/layout/footer";
import HeaderTwo from "../../component/layout/headertwo";
import BannerTwo from '../../component/section/bannertwo';
import AboutSectionCreators from "../../component/section/about_creators";
import AboutSectionPublishers from "../../component/section/about_publishers";
import Navigate from "../../../../util/Navigate";
import { Helmet } from 'react-helmet-async';
import Glitch from 'glitch-javascript-sdk';
import { Link } from "react-router-dom";
import withRouter from "../../../../util/withRouter";

/**
 * HeroChatBanner
 * This is the large hero section that prompts the user to ask a question about game marketing,
 * similar to ChatGPT/Grok. It occupies the full viewport height (minus any parent constraints),
 * includes category buttons and sub-prompts, and redirects depending on user login state.
 */
class HeroChatBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: "",
            activeCategory: null,
            showModal: false,
        };
    }

    // Predefined categories, each with 4 sub-prompts
    categories = [
        {
            name: "Ads",
            icon: "fa-bullhorn",
            prompts: [
                "What is the best way to create an ad campaign for my new game?",
                "How do I budget effectively for game ads?",
                "Which platforms are best for advertising a mobile game?",
                "Tips to measure ad performance for game campaigns?",
            ],
        },
        {
            name: "Influencers",
            icon: "fa-user-secret",
            prompts: [
                "How do I find the right influencers for my game?",
                "What are some best practices for influencer marketing?",
                "Tips for structuring influencer contracts?",
                "How do I track ROI from influencer campaigns?",
            ],
        },
        {
            name: "Social",
            icon: "fa-share-alt",
            prompts: [
                "Which social media platforms are most effective for game marketing?",
                "How often should I post about my game on social media?",
                "Tips for creating engaging social content for a new game?",
                "How to handle negative feedback or trolls on social media?",
            ],
        },
        {
            name: "Newsletter",
            icon: "fa-envelope",
            prompts: [
                "How do I build an email list for my upcoming game?",
                "What should I include in a weekly game newsletter?",
                "How to avoid spam filters with game marketing emails?",
                "Best practices for segmenting email subscribers for a game launch?",
            ],
        },
    ];

    handleShowModal = () => {
        this.setState({ showModal: true });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const questionTrimmed = this.state.question.trim();
        if (!questionTrimmed) {
            // If no question is entered, do nothing or show an alert
            return;
        }

        const encodedQuestion = encodeURIComponent(questionTrimmed);

        if (Glitch.util.Session.isLoggedIn()) {
            // Logged in: direct to the Chat page
            window.location = Navigate.titlesChatPage() + "?question=" + encodedQuestion;
        } else {
            // Not logged in: direct to Register with redirect back to the Chat + the question
            const chatUrl = Navigate.titlesChatPage() + "?question=" + encodedQuestion;
            const registerUrl =
                Navigate.publishersOnboardingStep1Page() + "?redirect=" + encodeURIComponent(chatUrl);
            window.location = registerUrl;
        }
    };

    handleCategoryClick = (category) => {
        // Toggle the category open/closed
        this.setState((prevState) => ({
            activeCategory: prevState.activeCategory === category ? null : category,
        }));
    };

    handlePromptClick = (prompt) => {
        // Set text area to the chosen prompt
        this.setState({ question: prompt });
    };

    render() {
        return (
            <section
                className="hero-chat-banner d-flex flex-column justify-content-center align-items-center"
                style={{
                    width: "100%",
                    minHeight: "100vh",
                    backgroundColor: "#080e37",
                    padding: "60px 15px",
                }}
            >
                <div className="container" style={{ maxWidth: "900px" }}>
                    <div className="text-center mb-4">
                        <h1
                            style={{
                                fontFamily: "'Oswald', sans-serif",
                                textTransform: "uppercase",
                                marginBottom: "1rem",
                                fontSize: "2.5rem",
                            }}
                        >
                            Welcome To Glitch
                        </h1>
                        <h2
                            style={{
                                fontFamily: "'Barlow', sans-serif",
                                fontSize: "1.5rem",
                                fontWeight: "normal",
                                textTransform: "none",
                            }}
                        >
                            Ask Me Anything About Your Game's Marketing
                        </h2>
                    </div>

                    <form onSubmit={this.handleSubmit} className="position-relative">
                        <div
                            className="input-group"
                            style={{
                                borderRadius: "8px",
                                overflow: "hidden",
                                boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
                            }}
                        >
                            <textarea
                                className="form-control"
                                style={{
                                    resize: "none",
                                    border: "none",
                                    height: "100px",
                                    color: "#fff",
                                    backgroundColor: "rgba(255,255,255,0.1)",
                                }}
                                value={this.state.question}
                                onChange={(e) => this.setState({ question: e.target.value })}
                            />
                            <button
                                type="submit"
                                className="btn"
                                style={{
                                    position: "absolute",
                                    bottom: "15px",
                                    right: "15px",
                                    background: "none",
                                    border: "none",
                                    color: "#ff0052",
                                    zIndex: 1000,
                                }}
                                aria-label="Submit question"
                            >
                                <i className="fa fa-paper-plane fa-2x"></i>
                            </button>
                        </div>
                    </form>

                    {/* Category Buttons */}
                    <div
                        className="d-flex flex-wrap justify-content-center align-items-center mt-4"
                        style={{ gap: "1rem" }}
                    >
                        {this.categories.map((cat) => (
                            <div
                                key={cat.name}
                                onClick={() => this.handleCategoryClick(cat.name)}
                                className="text-center"
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: "#232a5c",
                                    borderRadius: "50px",
                                    padding: "10px 20px",
                                    color: "#fff",
                                    display: "inline-flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    fontWeight: "bold",
                                    transition: "all 0.3s",
                                    textTransform: "uppercase",
                                }}
                            >
                                <i className={`fa ${cat.icon}`} aria-hidden="true"></i>{" "}
                                {cat.name}
                            </div>
                        ))}
                    </div>

                    {/* Sub-Prompts for the active category */}
                    {this.categories.map((cat) => {
                        if (this.state.activeCategory === cat.name) {
                            return (
                                <div key={cat.name} className="mt-3">
                                    <div
                                        className="d-flex flex-wrap justify-content-center"
                                        style={{ gap: "1rem" }}
                                    >
                                        {cat.prompts.map((prompt, idx) => (
                                            <button
                                                type="button"
                                                key={idx}
                                                className="btn"
                                                style={{
                                                    backgroundColor: "#232a5c",
                                                    borderRadius: "8px",
                                                    color: "#fff",
                                                    padding: "8px 15px",
                                                    border: "none",
                                                    textAlign: "left",
                                                    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                                                    fontSize: "0.9rem",
                                                    maxWidth: "300px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                }}
                                                onClick={() => this.handlePromptClick(prompt)}
                                            >
                                                {prompt}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}

                    <div className="text-center mt-5">
                        <button className="btn btn-danger" onClick={this.handleShowModal} ><i class="fas fa-video"></i> How It Works</button>
                    </div>
                </div>

                {this.state.showModal && (
                    <div
                        className="modal fade show d-block"
                        tabIndex="-1"
                        role="dialog"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
                    >
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content bg-dark text-white border-0">
                                <div className="modal-header border-0">
                                    <h5 className="modal-title">How Glitch Works</h5>
                                    <button
                                        type="button"
                                        className="btn-close btn-close-white"
                                        onClick={this.handleCloseModal}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body p-0">
                                    <div className="ratio ratio-16x9">
                                        <iframe
                                            width="100%"
                                            height="100%"
                                            src="https://www.youtube.com/embed/dIYpWApbzVA?autoplay=1&vq=hd1080"
                                            title="How Glitch Works"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </section>
        );
    }
}

/**
 * WhatIsGlitchSection
 * Replicates HubSpot's style for explaining "What is Glitch?" with an image on the left
 * and descriptive text on the right.
 */
function WhatIsGlitchSection() {
    return (
        <section
            id="homepage_company_story"
            className="wf-section wf-company-story -light -padding-top-md -padding-bottom-md"
            style={{ backgroundColor: "#232a5c", color: "#fff" }}
        >
            <div className="wf-section-wrapper" style={{ padding: "60px 0" }}>
                <div
                    className="wf-company-story_grid - -medium-media container"
                    tabIndex="-1"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "40px",
                        alignItems: "center",
                        maxWidth: "1200px",
                    }}
                >
                    {/* Left side: image */}
                    <div
                        className="wf-company-story_media"
                        style={{ textAlign: "center" }}
                    >
                        <img
                            src="/assets/images/home/all-in-one.png"
                            alt="What is Glitch"
                            style={{
                                maxWidth: "100%",
                                borderRadius: "8px",
                                boxShadow: "0 2px 10px rgba(0,0,0,0.3)",
                            }}
                        />
                    </div>

                    {/* Right side: text content */}
                    <div className="wf-company-story_rich-text -light">
                        <h2
                            className="wf-company-story_rich-text-heading"
                            style={{ fontSize: "2rem", marginBottom: "1rem" }}
                        >
                            What is Glitch?
                        </h2>
                        <div className="cl-rich-text" style={{ lineHeight: "1.6" }}>
                            <p style={{ marginBottom: "1rem" }}>
                                Glitch makes game marketing easy, empowering indie devs with
                                tools and automation once only available to AAA studios. From
                                influencer outreach to community building and playtesting, our
                                all-in-one platform simplifies marketing so you can focus on
                                creating. With free resources and a transparent approach, Glitch
                                helps your game stand out and succeed.
                            </p>
                        </div>

                        {/* CTA Buttons */}
                        <div
                            className="wf-company-story_cta-buttons d-flex flex-wrap"
                            style={{ gap: "10px" }}
                        >
                            <div className="wf-company-story_cta-button">
                                <Link
                                    className="cl-button -primary -medium homepage-platform-demo"
                                    to={Navigate.demoPage()}
                                    style={{
                                        backgroundColor: "#ff0052",
                                        color: "#fff",
                                        padding: "12px 24px",
                                        borderRadius: "4px",
                                        textDecoration: "none",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Get A Demo
                                </Link>
                            </div>
                            <div className="wf-company-story_cta-button">
                                <Link
                                    className="cl-button -secondary -medium homepage-platform-free"
                                    to={Navigate.publishersOnboardingStep1Page()}
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "2px solid #ff0052",
                                        color: "#ff0052",
                                        padding: "10px 24px",
                                        borderRadius: "4px",
                                        textDecoration: "none",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Get Started For Free
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

/**
 * GlitchFeaturesSection
 * A new section describing Glitch’s core features, inspired by HubSpot's layout.
 * We wrap the existing feature content (cards) in a styled container with headings, CTA, etc.
 */
function GlitchFeaturesSection() {
    return (
        <section
            id="glitch_features"
            className="wf-section -neutral -padding-top-md -padding-bottom-xs"
            style={{ backgroundColor: "#080e37", color: "#fff" }}
        >
            <div className="wf-section-wrapper" style={{ padding: "60px 0" }}>
                <div className="wf-crm-platform container">
                    {/* Section Heading / Intro */}
                    <div className="wf-crm-platform__container text-center" style={{ marginBottom: "40px" }}>
                        <h2
                            className="wf-crm-platform__heading -neutral"
                            style={{ fontSize: "2rem", marginBottom: "1rem" }}
                        >
                            Everything You Need to Market Your Game in One Place
                        </h2>
                        <div
                            className="wf-crm-platform__description mx-auto"
                            style={{ maxWidth: "700px" }}
                        >
                            Glitch unifies influencer outreach, social scheduling, media kits, newsletters, and
                            more into a single, cohesive platform – so you can stay focused on building the
                            next great game.
                        </div>

                        {/* CTAs (optional, to mirror HubSpot style) */}
                        <div
                            className="wf-crm-platform__cta-buttons mt-4 d-flex justify-content-center"
                            style={{ gap: "10px" }}
                        >
                            <div className="wf-crm-platform__cta-button">
                                <Link
                                    className="cl-button -primary -medium"
                                    to={Navigate.demoPage()}
                                    style={{
                                        backgroundColor: "#ff0052",
                                        color: "#fff",
                                        padding: "12px 24px",
                                        borderRadius: "4px",
                                        textDecoration: "none",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Get A Demo
                                </Link>
                            </div>
                            <div className="wf-crm-platform__cta-button">
                                <Link
                                    className="cl-button -secondary -medium"
                                    to={Navigate.publishersOnboardingStep1Page()}
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "2px solid #ff0052",
                                        color: "#ff0052",
                                        padding: "10px 24px",
                                        borderRadius: "4px",
                                        textDecoration: "none",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Get Started For Free
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* ========== Core Features Section ========== */}
                    <div className="row text-center">
                        {/* Feature 1 */}
                        <div className="col-md-4 mb-4">
                            <div className="card bg-transparent border-0 h-100 text-white">
                                <div className="card-body">
                                    <h4>Influencer CRM &amp; Database</h4>
                                    <img
                                        src="/assets/images/publishers/overview-crm.png"
                                        style={{ maxHeight: "200px" }}
                                        className="img-fluid rounded"
                                        alt="Influencer CRM"
                                    />
                                    <p className="mt-3">
                                        Discover, track, and manage thousands of creator relationships with our
                                        outreach tools and performance analytics.
                                    </p>
                                    <a
                                        className="default-button reverse-effect mt-3"
                                        href={Navigate.publishersCRMPage()}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <span>Read More</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Feature 2 */}
                        <div className="col-md-4 mb-4">
                            <div className="card bg-transparent border-0 h-100 text-white">
                                <div className="card-body">
                                    <h4>Social Scheduler &amp; Devlogs</h4>
                                    <img
                                        src="/assets/images/publishers/overview-social.jpg"
                                        style={{ maxHeight: "200px" }}
                                        className="img-fluid rounded"
                                        alt="Social Scheduler"
                                    />
                                    <p className="mt-3">
                                        Automate your social content with our weekly scheduler. Turn dev logs into
                                        multiple social posts in just minutes.
                                    </p>
                                    <a
                                        className="default-button reverse-effect mt-3"
                                        href={Navigate.publishersSocialPage()}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <span>Learn More</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Feature 3 */}
                        <div className="col-md-4 mb-4">
                            <div className="card bg-transparent border-0 h-100 text-white">
                                <div className="card-body">
                                    <h4>Play Tester Database</h4>
                                    <img
                                        src="/assets/images/publishers/overview-testers.png"
                                        style={{ maxHeight: "200px" }}
                                        className="img-fluid rounded"
                                        alt="Play Tester Database"
                                    />
                                    <p className="mt-3">
                                        Gather honest feedback before launch with our built-in network of avid gamers
                                        eager to playtest.
                                    </p>
                                    <a
                                        className="default-button reverse-effect mt-3"
                                        href={Navigate.publishersPlayTesterPage()}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <span>Details Here</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row text-center mt-4">
                        {/* Feature 4 */}
                        <div className="col-md-4 mb-4">
                            <div className="card bg-transparent border-0 h-100 text-white">
                                <div className="card-body">
                                    <h4>Media Kits</h4>
                                    <img
                                        src="/assets/images/publishers/overview-press.jpg"
                                        style={{ maxHeight: "200px" }}
                                        className="img-fluid rounded"
                                        alt="Media Kits"
                                    />
                                    <p className="mt-3">
                                        Impress journalists and podcasters with sleek media kits for your game,
                                        created in a matter of minutes.
                                    </p>
                                    <a
                                        className="default-button reverse-effect mt-3"
                                        href={Navigate.publishersMediakitPage()}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <span>View Media Kits</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Feature 5 */}
                        <div className="col-md-4 mb-4">
                            <div className="card bg-transparent border-0 h-100 text-white">
                                <div className="card-body">
                                    <h4>Community Platform</h4>
                                    <img
                                        src="/assets/images/publishers/overview-community.jpg"
                                        style={{ maxHeight: "200px" }}
                                        className="img-fluid rounded"
                                        alt="Community Platform"
                                    />
                                    <p className="mt-3">
                                        Build your fanbase with dedicated community tools—live streaming, short-form
                                        content, user engagement, and more.
                                    </p>
                                    <a
                                        className="default-button reverse-effect mt-3"
                                        href={Navigate.publishersCommunityPage()}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <span>Check It Out</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Feature 6 */}
                        <div className="col-md-4 mb-4">
                            <div className="card bg-transparent border-0 h-100 text-white">
                                <div className="card-body">
                                    <h4>Newsletter</h4>
                                    <img
                                        src="/assets/images/publishers/overview-newsletter.jpg"
                                        style={{ maxHeight: "200px" }}
                                        className="img-fluid rounded"
                                        alt="Newsletter"
                                    />
                                    <p className="mt-3">
                                        Keep players in the loop with newsletters, with direct access to
                                        their contact information and inboxes.
                                    </p>
                                    <a
                                        className="default-button reverse-effect mt-3"
                                        href={Navigate.publishersNewsletterPage()}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <span>See Newsletters</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End of Core Features */}
                </div>
            </div>
        </section>
    );
}

/**
 * HomeTwo component
 * Includes:
 * - Helmet for SEO
 * - HeaderTwo
 * - HeroChatBanner (full screen)
 * - WhatIsGlitchSection
 * - GlitchFeaturesSection (newly added)
 * - About sections
 * - Footer
 */
class HomeTwo extends Component {
    render() {
        return (
            <>
                <Helmet prioritizeSeoTags>
                    <title>Glitch –  Marketing Automation Tools for Game Developers</title>
                    <meta
                        name="description"
                        content="Glitch helps game developers market their games with influencer outreach, social media management, optimized advertising, media kits, and more in an all in one platform."
                    />
                    <meta
                        property="og:title"
                        content="Glitch –  Marketing Automation Tools for Game Developers"
                    />
                    <meta
                        property="og:description"
                        content="Glitch helps game developers market their games with influencer outreach, social media management, optimized advertising, media kits, and more in an all in one platform."
                    />
                    <meta
                        property="og:image"
                        content="https://www.glitch.fun/assets/images/home/ask_me_anything.png"
                    />
                    <meta
                        property="og:url"
                        content={
                            typeof window !== "undefined"
                                ? window.location.href
                                : "https://www.glitch.fun"
                        }
                    />
                    <meta property="og:type" content="website" />
                    <meta
                        name="twitter:title"
                        content="Glitch –  Marketing Automation Tools for Game Developers"
                    />
                    <meta
                        name="twitter:description"
                        content="Glitch helps game developers market their games with influencer outreach, social media management, optimized advertising, media kits, and more in an all in one platform."
                    />
                    <meta
                        name="twitter:image"
                        content="https://www.glitch.fun/assets/images/home/ask_me_anything.png"
                    />
                </Helmet>

                <Fragment>
                    <HeaderTwo />

                    {/* Full-Height Hero Chat Banner */}
                    <HeroChatBanner />

                    {/* "What is Glitch?" Section */}
                    <WhatIsGlitchSection />

                    {/* Glitch Features Section (similar to HubSpot style) */}
                    <GlitchFeaturesSection />

                    <Footer />
                </Fragment>
            </>
        );
    }
}

export default withRouter(HomeTwo);
