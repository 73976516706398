import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import timeouts from '../../../../constants/timeouts';
import Navigate from '../../../../util/Navigate';
import withRouter from '../../../../util/withRouter';
import Danger from '../../component/alerts/Danger';
import Loading from '../../component/alerts/Loading';
import Footer from '../../component/layout/footer';
import Header from '../../component/layout/header';
import PageHeader from '../../component/layout/pageheader';
import SocialMedia from '../../component/section/socialmedia';
import Glitch from 'glitch-javascript-sdk';

const PublisherOnboardinStep1Page = (props) => {
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isBrowser, setIsBrowser] = useState(false); // State to detect browser environment

    const navigate = useNavigate();

    useEffect(() => {
        // Check if the code is running in a browser environment
        setIsBrowser(typeof window !== 'undefined');

        if (typeof window !== 'undefined') {
            setTimeout(() => {
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                });
                let iscohost = params.iscohost;
                if (iscohost && Glitch.util.Session.isLoggedIn()) {
                    goToNextScreen();
                }
            }, 1000);
        }
    }, []);

    const register = (event) => {
        event.preventDefault();

        // Clear previous errors
        setErrors({});

        // Check if terms of service are accepted
        if (!termsAccepted) {
            setErrors({ terms: ['You must agree to the terms of service.'] });
            return;
        }

        const data = { first_name, last_name, email, password, username };

        setIsLoading(true);

        Glitch.api.Auth.register(data).then((response) => {
            Glitch.util.Storage.setAuthToken(response.data.data.token.access_token);
            Glitch.util.Storage.set('user_id', response.data.data.id);

            Glitch.util.Session.processAuthentication(response.data.data);

            setIsLoading(false);

            goToNextScreen();
        }).catch((error) => {
            setIsLoading(false);
            if (error.response && error.response.data) {
                setErrors(error.response.data);

                setTimeout(() => {
                    setErrors({});
                }, timeouts.error_message_timeout);
            }
        });
    };

    const goToNextScreen = () => {

        if (typeof window !== "undefined") {
            const params = new URLSearchParams(window.location.search);
            const redirect = params.get('redirect');
            let nextPageUrl = Navigate.publishersOnboardingStep2Page();
            if (redirect) {
                nextPageUrl += `?redirect=${encodeURIComponent(redirect)}`;
            }
            navigate(nextPageUrl);
        }
    };

    return (
        <Fragment>
            <Header position="relative" />
            <div className="login-section padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">Registration for Publisher/Indie Developers</h3>
                        <p>Register to sign up for an account to start promoting your game.</p>
                        <hr />

                        <h6 className="text-center mt-3 mb-3">Register With Social Media</h6>
                        {isBrowser && (
                            <SocialMedia redirect={`${window.location.origin}${Navigate.publishersOnboardingStep2Page()}`} />
                        )}
                        <span className="or"><span>or</span></span>
                        <h3 className="title">Sign-Up Below</h3>

                        <form method="post" className="account-form text-start" onSubmit={register}>
                            <div className="form-group">
                                <label className="form-label">First Name</label>
                                <input
                                    type="text"
                                    value={first_name}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    placeholder="First Name *"
                                />
                                {errors.first_name && errors.first_name.map((name, index) => <Danger message={name} key={index} />)}
                            </div>
                            <div className="form-group">
                                <label className="form-label">Last Name</label>
                                <input
                                    type="text"
                                    value={last_name}
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder="Last Name *"
                                />
                                {errors.last_name && errors.last_name.map((name, index) => <Danger message={name} key={index} />)}
                            </div>
                            <div className="form-group">
                                <label className="form-label">Username</label>
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Username *"
                                />
                                {errors.username && errors.username.map((name, index) => <Danger message={name} key={index} />)}
                            </div>
                            <div className="form-group">
                                <label className="form-label">Email</label>
                                <input
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Your email *"
                                />
                                {errors.email && errors.email.map((name, index) => <Danger message={name} key={index} />)}
                            </div>
                            <div className="form-group">
                                <label className="form-label">Password</label>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password *"
                                />
                                {errors.password && errors.password.map((name, index) => <Danger message={name} key={index} />)}
                            </div>
                            <div className="form-group">
                                <label className="form-label">Confirm Password</label>
                                <input
                                    type="password"
                                    value={confirm_password}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    placeholder="Confirm Password *"
                                />
                            </div>
                            <div className="form-group-1">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                />&nbsp;&nbsp;&nbsp;

                                <label className="form-label">Opt-In To Receive Emails</label>&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="form-group-1">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={termsAccepted}
                                    onChange={(e) => setTermsAccepted(e.target.checked)}
                                />&nbsp;&nbsp;&nbsp;

                                <label className="form-label">Agree To Our <Link to={Navigate.termsPage()} target="_blank">Terms of Service</Link></label>&nbsp;&nbsp;&nbsp;
                                {errors.terms && errors.terms.map((error, index) => <Danger message={error} key={index} />)}
                            </div>
                            <div className="form-group">
                                <button type="submit" className="d-block default-button"><span>{isLoading ? <Loading /> : ''} Get Started Now</span></button>
                            </div>
                        </form>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">Are you a member? <Link to={Navigate.authLogin()}>Login</Link></span>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default PublisherOnboardinStep1Page;
