import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet-async";
import HeaderTwo from "../../component/layout/headertwo";
import Footer from "../../component/layout/footer";

/**
 * DemoPage
 * A dedicated page for users to book a demo via Google Calendar.
 * Mimics HubSpot’s “Get a Demo” style:
 * - A top hero/intro with background shapes or images
 * - A two-column layout: Left side has details/features/benefits, Right side has the Google Calendar iframe
 * - Responsive, visually pleasing design consistent with your color palette
 */
class DemoPage extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Schedule a Demo | Glitch</title>
          <meta
            name="description"
            content="Book a free demo with Glitch to learn how our all-in-one platform can supercharge your game's marketing efforts."
          />
          <meta property="og:title" content="Glitch - Book a Demo" />
          <meta
            property="og:description"
            content="Discover how Glitch can streamline your game marketing through automation and an all-in-one platform. Book a free demo today."
          />
          <meta property="og:type" content="website" />
        </Helmet>
        <Fragment>
          <HeaderTwo position={'relative'} />

          {/* 
            Main Demo Section
            Adapts styling from HubSpot’s “offers-demo-section” concept.
            We include decorative backgrounds or shapes if needed, 
            plus a two-column layout for features + Google Calendar embed. 
          */}
          <section
            className="offers-demo-section -neutral"
            style={{
              position: "relative",
              overflow: "hidden",
              backgroundColor: "#080e37",
              color: "#fff",
              padding: "60px 15px",
            }}
          >
            {/* Optional decorative images in corners, replicate HubSpot's style */}
            <div style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}>
              <img
                src="/assets/images/demo/demo-corner-right.svg" 
                alt=""
                style={{ width: "200px", maxWidth: "100%" }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 0, left: 0, zIndex: 1 }}>
              <img
                src="/assets/images/demo/demo-corner-left.svg"
                alt=""
                style={{ width: "200px", maxWidth: "100%" }}
              />
            </div>

            <div
              className="offers-demo-section-wrapper container"
              style={{ position: "relative", zIndex: 2 }}
            >
              <div className="row">
                {/* Left Column: Title, short text, popular features, CTA, etc. */}
                <div className="offers-demo-section-left col-md-6 d-flex flex-column justify-content-center">
                  <div style={{ marginBottom: "2rem" }}>
                    <h1
                      style={{
                        fontSize: "2rem",
                        marginBottom: "1rem",
                        fontFamily: "'Oswald', sans-serif",
                        textTransform: "uppercase",
                      }}
                    >
                      Get a Free Demo of Glitch
                    </h1>
                    <p style={{ fontSize: "1.1rem", lineHeight: "1.6" }}>
                      Glitch is the all-in-one solution for game marketing –
                      combining influencer outreach, social automation,
                      newsletters, and more on a single platform. Book a free
                      demo to see how Glitch can streamline your marketing and
                      free you up to focus on what really matters: building a
                      great game.
                    </p>

                    <a
                      href="#demo-calendar-embed"
                      className="btn mt-3"
                      style={{
                        backgroundColor: "#ff0052",
                        color: "#fff",
                        fontWeight: "bold",
                        borderRadius: "4px",
                        padding: "12px 24px",
                        textDecoration: "none",
                      }}
                    >
                      Book Your Demo
                    </a>
                  </div>

                  {/* Example: popular features lists, similar to HubSpot’s “Popular Features” block */}
                  <div>
                    <h2 style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
                      Popular Features
                    </h2>
                    <div className="row">
                      {/* Feature #1 */}
                      <div className="col-sm-6 mb-3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "#ff0052",
                              display: "inline-flex",
                              borderRadius: "50%",
                              width: "24px",
                              height: "24px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                          <span>Influencer CRM</span>
                        </div>
                      </div>
                      {/* Feature #2 */}
                      <div className="col-sm-6 mb-3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "#ff0052",
                              display: "inline-flex",
                              borderRadius: "50%",
                              width: "24px",
                              height: "24px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                          <span>Media Kits</span>
                        </div>
                      </div>
                      {/* Feature #3 */}
                      <div className="col-sm-6 mb-3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "#ff0052",
                              display: "inline-flex",
                              borderRadius: "50%",
                              width: "24px",
                              height: "24px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                          <span>Social Media Management</span>
                        </div>
                      </div>
                      {/* Feature #4 */}
                      <div className="col-sm-6 mb-3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "#ff0052",
                              display: "inline-flex",
                              borderRadius: "50%",
                              width: "24px",
                              height: "24px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                          <span>Playtester Database</span>
                        </div>
                      </div>
                      {/* Feature #5 */}
                      <div className="col-sm-6 mb-3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "#ff0052",
                              display: "inline-flex",
                              borderRadius: "50%",
                              width: "24px",
                              height: "24px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                          <span>Newsletters</span>
                        </div>
                      </div>
                      {/* Feature #6 */}
                      <div className="col-sm-6 mb-3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "#ff0052",
                              display: "inline-flex",
                              borderRadius: "50%",
                              width: "24px",
                              height: "24px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </span>
                          <span>Ads Management</span>
                        </div>
                      </div>
                    </div>

                    <p style={{ marginTop: "1rem", lineHeight: "1.6" }}>
                      Join the many developers who trust Glitch to unify their
                      marketing tools and reduce complexity. Over
                      <strong> 1,000+ developers </strong> from
                      <strong> 15+ countries </strong> use Glitch to run
                      effective campaigns, gather feedback, and build thriving
                      communities.
                    </p>
                  </div>
                </div>

                {/* Right Column: The Google Calendar scheduling embed */}
                <div
                  className="offers-demo-section-right col-md-6 mt-5 mt-md-0"
                  style={{}}
                >
                  <div
                    id="demo-calendar-embed"
                    style={{
                      backgroundColor: "#232a5c",
                      borderRadius: "8px",
                      padding: "20px",
                      marginTop: "100px",
                    }}
                  >
                    <h2
                      style={{
                        fontSize: "1.5rem",
                        marginBottom: "1rem",
                        textAlign: "center",
                      }}
                    >
                      Pick a Date &amp; Time
                    </h2>
                    <p
                      style={{
                        fontSize: "1rem",
                        textAlign: "center",
                        marginBottom: "2rem",
                        lineHeight: "1.6",
                      }}
                    >
                      Ready to talk? Select a convenient slot from our Google
                      Calendar below.
                    </p>
                    {/* 
                      Google Calendar Appointment Scheduling 
                      Inline Code Provided 
                    */}
                    <div style={{ width: "100%", position: "relative", overflow: "hidden", backgroundColor: 'white' }}>
                      <iframe
                        title="Glitch Demo Scheduler"
                        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3WU2yGUVxruIZdufcLO5ODzwHNtZ6fz4dED4K6-vnCkI01pdXeNEgC7-YJXwRomWUNwAtLM3_P?gv=true"
                        style={{
                          border: "0",
                          width: "100%",
                          height: "600px",
                        }}
                        frameBorder="0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </Fragment>
      </>
    );
  }
}

export default DemoPage;
